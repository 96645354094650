@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: "Quicksand", sans-serif;
    background-color: theme("colors.denim");
    overflow-x: hidden;
    width: 100vw;
    box-sizing: border-box;
  }
}

@layer utilities {
  .font-title {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply capitalize;
}

.home-hero-background {
  background-image: url("assets/img/error-404/paisaje-forager-foundation.png");
}

@layer components {
  .with-transition {
    @apply transition-all duration-150 ease-in;
  }

  .app-primary-button {
    @apply grid place-items-center px-5 py-2 bg-primary text-white font-bold rounded-full
    lg:hover:bg-denim capitalize;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25);
  }

  .app-secondary-button {
    @apply bg-white text-primary font-bold px-10 py-4 mx-auto block w-fit rounded-full
    lg:hover:bg-secondary lg:hover:text-white capitalize;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25);
  }

  /*HERO STYLES*/
  .app-hero {
    background-image: var(--bg-image);
    @apply bg-no-repeat bg-cover bg-center;
  }
  .app-hero.gradient-t {
    background: linear-gradient(to bottom, theme("colors.denim"), transparent),
      var(--bg-image);
    @apply bg-no-repeat bg-cover bg-center;
  }
  .app-hero.gradient-y {
    background: linear-gradient(
        to bottom,
        theme("colors.denim"),
        transparent,
        theme("colors.denim")
      ),
      var(--bg-image);
    @apply bg-no-repeat bg-cover bg-center;
  }
  .app-hero.gradient-b {
    background: linear-gradient(
        to bottom,

        transparent,
        theme("colors.denim")
      ),
      var(--bg-image);
    @apply bg-no-repeat bg-cover bg-center;
  }
  .app-hero.gradient-tl {
    background-image: linear-gradient(
        to right,
        theme("colors.denim"),
        transparent
      ),
      linear-gradient(
        to bottom,
        theme("colors.denim"),
        transparent,
        theme("colors.denim")
      ),
      var(--bg-image);
    @apply bg-no-repeat bg-cover bg-center md:bg-bottom;
  }
  .app-hero__container {
    @apply container flex justify-center md:justify-start h-[22.5rem] lg:h-[27.5rem];
  }
  .app-hero__title {
    @apply max-w-lg my-auto font-title lg:text-6xl text-left font-bold text-white;
  }
  .app-hero__title--small {
    @apply block text-2xl text-primary;
  }
  /* END HERO STYLES*/
}

.bg-visit-yale {
  background-image: url("assets/img/yale/bg-visit-yale-mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-navbar {
  background: linear-gradient(180deg, #2b2d3a 0%, rgba(43, 45, 58, 0) 100%);
}

#our-story-hero {
  background-image: linear-gradient(
      to bottom,
      theme("colors.denim"),
      transparent,
      theme("colors.denim")
    ),
    url("assets/img/our-story/our-story-hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 60%;
}

#contact-get-intouch {
  background-image: linear-gradient(
      to bottom,
      theme("colors.denim"),
      transparent
    ),
    url("assets/img/contact/forager-fundation-get-intouch-mobile.png");
  background-size: cover;
  background-repeat: no-repeat;
}

#our-story-forest {
  background-image: linear-gradient(
      to bottom,
      theme("colors.denim"),
      transparent 30%
    ),
    url("assets/img/our-story/our-story-forest.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@screen md {
  #contact-get-intouch {
    background-image: linear-gradient(
        to bottom,
        theme("colors.denim"),
        transparent,
        theme("colors.denim")
      ),
      url("assets/img/contact/forager-fundation-get-intouch.png");
    background-size: 100% 100%;
  }
}

@screen md {
  #our-story-hero {
    background-image: linear-gradient(
        to right,
        theme("colors.denim"),
        transparent
      ),
      linear-gradient(
        to bottom,
        theme("colors.denim"),
        transparent,
        theme("colors.denim")
      ),
      url("assets/img/our-story/our-story-hero.png");
    background-size: 100% 100%;
  }

  .bg-visit-yale {
    background-image: url("assets/img/yale//bg-visit-yale-desktop.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
